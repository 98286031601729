
import { Options, Vue } from 'vue-class-component';
import { AuthorClient } from '@/services/services';
import { checkIfHasLocalizedValue } from '@/utils';
import { StorageServices } from '@/services/StorageServices';
import SwiperGallery from '@/components/modals/swiperGalleryModal.vue';
import GiveFeedback from '@/components/giveFeedback.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        GiveFeedback
    }
})
export default class Author extends Vue {

    author: OM.GetAuthorVm = null;
    showMore: boolean = false;
    needLessButton: boolean = false;
    loaded: boolean = false;

    mostra: OM.GetExhibitionVm = StorageServices.getExhibition();

    created() {
        var authorId = this.$route.params.authorIdentifier.toString();
        var exhibitionIdentifier = store.state.exhibition.identifier;
        AuthorClient.getDetail(authorId, exhibitionIdentifier)
        .then(x => {
            this.author = x;
            this.loaded = true;

            this.$nextTick(() => {
                let authorDescriptionEl = <HTMLElement>this.$refs.authorDescription;
                let windowTreshold = window.innerHeight * 19 / 100;
                if(parseFloat(getComputedStyle(authorDescriptionEl).height) > windowTreshold){
                    this.showMore = true;
                    this.needLessButton = true;
                }
            });

            // gtag('event', "author", {
            //     'event_category': 'momiapp',
            //     'event_label': vc.author.name + ' ' + vc.author.surname,
            //     'value': ''
            // });  
        })
    }


    showSwiperGalleryModal(gallery: OM.LocalizedThumbnailItem[], index: number, isVideo: boolean) {
        var urls = [];
        gallery.forEach(element => {
            urls.push(element.url);
        });

        this.$opModal.show(SwiperGallery, {
            isVideo: isVideo,
            gallery: urls,
            index: index
        });
    }

    get showDescription() {
        return checkIfHasLocalizedValue(this.author.descriptionText);
    }
}
